require('./bootstrap');


import flatpckr from 'flatpickr';

window.flatpckr = flatpckr;

const Spanish = require("flatpickr/dist/l10n/es.js").default.es;
const english = require("flatpickr/dist/l10n/default.js").default.en;
const French = require("flatpickr/dist/l10n/fr.js").default.fr;
const Italian = require("flatpickr/dist/l10n/it.js").default.it;
const Portuguese = require("flatpickr/dist/l10n/pt.js").default.pt;
const Russian = require("flatpickr/dist/l10n/ru.js").default.ru;
const German = require("flatpickr/dist/l10n/de.js").default.de;
const Swedish = require("flatpickr/dist/l10n/sv.js").default.sv;
const Catalan = require("flatpickr/dist/l10n/cat.js").default.cat;



