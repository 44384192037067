window._ = require('lodash');

try {
    window.$ = require('jquery');
    window.jQuery = require('jquery');
    window.Popper = require('@popperjs/core');
    window.bootstrap = require('bootstrap');
    require("bootstrap-select");

    window.dt = require( 'datatables.net' )( window, $ );

} catch (e) {}

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require('axios');

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

import Echo from 'laravel-echo';

import Pusher from 'pusher-js';
window.Pusher = Pusher;
window.Echo = Echo;
/*
window.Echo = new Echo({
    broadcaster: 'reverb',
    key: 'zaslsoG5O0OMvlkfso7LaKom',
    wsHost: 'ws.app.bedloop.com',
    wssHost: 'wss.app.bedloop.com',
    wsPort: 8080,
    wssPort: 443,
    forceTLS: false,
    enabledTransports: ['ws', 'wss']
});
*/
